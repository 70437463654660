.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.board-wrapper-container>div>.smooth-dnd-container,
.horizontal {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sketch-picker>div:nth-child(2)>div:nth-child(1)>div:nth-child(2) {
    display: none;
}

.font-size-16px {
    font-size: 16px;
}

.transition-duration-300ms {
    transition-duration: 300ms;
}

.conversation-text-preview {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #A1A5B7 !important
}

.conversation-option-icon {
    transition-duration: 300ms;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}